import  {useState, useEffect} from 'react';
import {useParams } from 'react-router-dom';
import './styles.scss';
import 'react-image-crop/dist/ReactCrop.css';
import {Amplify,DataStore} from 'aws-amplify';
import config from './aws-exports';
import {Post} from "./models"
import Posst from './Post';

Amplify.configure(config);


const Shared = () => {
  const id = useParams();
  const [post,setPost] = useState([]);

  useEffect (() => {
    async function getPost(postID){
      try{
        let data = await DataStore.query(Post, c => c.id("eq", postID));
        console.log(data);
        setPost(data);
        console.log(post);
      }
      catch (err){
        console.log(err);
      }
    }
    getPost(id.id);
  },[]);

  console.log(post);

  return(
    <div className="sharedPost">
    {post.map( (Model) => {
      console.log(Model);
      const varr = JSON.parse(Model.listtags);
      console.log(varr);
      return (
        <div >
          <Posst id={Model.id} userID={Model.userid} imgURL={Model.imgURL} timestamp={Model.createdAt} listTags={varr}/>
        </div>
      )
  
   })}
    </div>
  );
}

export default Shared;