import React, {useState, useEffect} from 'react';
import Loading from './Loading';
import Posts from './Posts';
import Navbar from './Navbar';
import ImgDropAndCrop from './ImgDropAndCrop';
import Login from './Login';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import {Amplify,DataStore,  Predicates, Auth, SortDirection, Hub} from 'aws-amplify';
import config from './aws-exports';
import {Post} from "./models";
import { Pagination } from '@aws-amplify/ui-react';
import Footer from './Footer';
import './style.css';
import ReactGA from 'react-ga4';
import Shared from './Shared';
import useAnalyticsEventTracker from './useAnalyticsEventTracker';


Amplify.configure(config);

function App(){
    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [logged, setLogged] = useState(false);
    const [userName,setUserName] = useState("");
    const [pageTokens, setPageTokens] = useState(['page2']);
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [hasMorePages, setHasMorePages] = useState(true);
    
    const TRACKING_ID = "G-ZFJLLTR46P";

    ReactGA.initialize(TRACKING_ID);

    const listener = Hub.listen("datastore", async hubData => {
      const  { event, data } = hubData.payload;

      if (event === "ready") {
       const data = await DataStore.query(Post, Predicates.ALL, {
       sort: s => s.createdAt(SortDirection.DESCENDING),
       page: currentPageIndex-1,
       limit: 10
        });
        setPosts(data);
      }
    })

    useEffect( () => {
    const fetchPostss = async() => {
        setLoading(true);
        const data = await DataStore.query(Post, Predicates.ALL, {
          sort: s => s.createdAt(SortDirection.DESCENDING),
          page: currentPageIndex-1,
          limit: 10
        });
        listener();
        
        if(data.length < 10 && data.length > 0 ){
          setPosts(data);
          setHasMorePages(false);
        }
        else if (data.length === 0){
          setHasMorePages(false);
        }
        else{
          setPosts(data);
          setHasMorePages(true);
        }
        setLoading(false);
  }
  fetchPostss();
},[currentPageIndex])

  const handleNextPage = async () => {
    console.log("Next page:");
    console.log(hasMorePages);
    console.log(currentPageIndex);
    console.log(pageTokens.length);
    if (hasMorePages && currentPageIndex === pageTokens.length) {
      const newpage = (currentPageIndex+1);
      setCurrentPageIndex(newpage);
      const  nextToken  = posts;
      if (!nextToken) {
        setHasMorePages(false);
      }
      setPageTokens([...pageTokens, nextToken]);
    }
  }

  const handlePreviousPage = () => {
    if (currentPageIndex > 1) {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  }

  const handleOnChange = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
  }

  const checkUser = async () => {
    try{
        const userr = await Auth.currentAuthenticatedUser();
        const user = userr.username;
        setLogged(true);
        setUserName(user);
    }
    catch (e){
        setLogged(false);
        setUserName("");
    }
  } 

  const updateNavbar = () => {
    this.checkUser();
  }
    useEffect(() => {
      checkUser();
    }, [])
    const gaEventTracker = useAnalyticsEventTracker('Loading posts');
    if (loading) {
      return (
        <main>
          <Loading />
        </main>
      )
    }
    if (posts.length === 0) {
      return (
        <BrowserRouter>
         <Navbar logged={logged} userName={userName}/>
          <Routes>
            <Route path="/" element={<Posts posts={posts} />} />
            <Route path="/ImgDropAndCrop" element={<ImgDropAndCrop />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="shared/:id"
              element={<Shared />}
              />
          </Routes>
          <Footer></Footer>
        </BrowserRouter>
      )
    }
    return (
      
        <BrowserRouter className="maincont">
         <Navbar logged={logged} userName={userName}/>
          <Routes>
            <Route path="/" element={<><Posts posts={posts} />
            <Pagination
              currentPage={currentPageIndex}
              totalPages={pageTokens.length+1}
              hasMorePages={hasMorePages}
              onNext={handleNextPage}
              onPrevious={() => setCurrentPageIndex(currentPageIndex - 1)}
              onChange={(pageIndex) => setCurrentPageIndex(pageIndex)} /></>} 
            />
            <Route path="/ImgDropAndCrop" element={<ImgDropAndCrop />} />
            <Route path="/login" element={<Login />} />
            <Route
              path="shared/:id"
              element={<Shared />}
              />
          </Routes>
          <Footer></Footer>
        </BrowserRouter>  
      );
}

export default App;

