import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import Upload from "./Upload";
import {Amplify,Auth} from 'aws-amplify';
import config from './aws-exports';
import Login from "./Login";
import "./style.css";
import useAnalyticsEventTracker from './useAnalyticsEventTracker';


Amplify.configure(config);


const fileTypes = ["JPG", "PNG", "GIF", "docx"];

export default function ImgDropAndCrop() {
  const [file, setFile] = useState(null);
  const [logged, setLogged] = useState(false);
  const handleChange = (file) => {
    setFile(file);
  };

  const isLogin = async () => {
    try{
        await Auth.currentAuthenticatedUser();
        setLogged(true);
    }
    catch{
      setLogged(false);
    }
  }
  isLogin();
;
  const gaEventTracker = useAnalyticsEventTracker('Upload picture');
  if(logged===true){
    if(file == null){
      return (
          <div className="imgDropDrag">
            <h1>Upload your setup picture here</h1>
            <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
            <p>{file ? `File name: ${file.name}` : "no files uploaded yet"}</p>
          </div>
      );
    }
    else{
      return(
          <Upload image={file}/>);
    }
  }
  else{
    return (<Login/>);
  }
}