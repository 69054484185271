import React from "react";
import ReactDOM from "react-dom";
import ReactAvatarEditor from 'react-avatar-editor';
import Mapper from "./mapper";

import "./style.css";


let newRot = 0;
let rotate = 0;
let scale = 0;
export default class Upload extends React.Component {
  constructor(props){
    super(props);
      this.state = {
        image: props.image,
        allowZoomOut: false,
        position: { x: 0.5, y: 0.5 },
        scale: 1,
        rotate: 0,
        borderRadius: 0,
        preview: null,
        width: 800,
        height: 450 ,
        triggerMapper: false,
        imageURL: null
      };
    }


  

  handleNewImage = e => {
    this.setState({ image: e.target.files[0] })
  }

  handleScale = e => {
    scale = parseFloat(e.target.value)
    this.setState({ scale })
  }

  handlePositionChange = position => {
    this.setState({ position })
  }

  handleRotate = e => {
    rotate = parseFloat(e.target.value)
    this.setState({ rotate })
  }

  rotateLeft = () => {
    newRot = this.state.rotate - 90;
    this.setState({rotate: newRot});
  }

  rotateRight = () => {
    newRot = this.state.rotate + 90;
    this.setState({rotate: newRot});
  }

  onBtnClk = () => {
    if(this.editor){
      const canvasScaled = this.editor.getImageScaledToCanvas();
      var binaryData = [];
      binaryData.push(canvasScaled);
      this.setState({imageURL: URL.createObjectURL(new Blob(binaryData, {type: "image"}))});
    }
   
    this.setState({triggerMapper: true});
  }

  setEditorRef = (editor) => (this.editor = editor)

  render() {

      if (this.state.triggerMapper === false){
        return (
          <div >
              <div className="uploadPicComp">
                <ReactAvatarEditor
                  ref={this.setEditorRef}
                  scale={parseFloat(this.state.scale)}
                  width={this.state.width}
                  height={this.state.height}
                  position={this.state.position}
                  onPositionChange={this.handlePositionChange}
                  rotate={parseFloat(this.state.rotate)}
                  borderRadius={this.state.width / (100 / this.state.borderRadius)}
                  image={this.state.image}
                  className="editor-canvas"
                />
              </div>
            <br />
            <div className="uploadPic">
            New File:
            <input name="newImage" type="file" onChange={this.handleNewImage} />
            <br />
            Zoom:
            <input
              name="scale"
              type="range"
              onChange={this.handleScale}
              min={this.state.allowZoomOut ? '0.1' : '1'}
              max="2"
              step="0.01"
              defaultValue="1"
            />
            <br />
            Rotate:
            <input
              name="rotate"
              type="range"
              onChange={this.handleRotate}
              min="-180"
              max="180"
              step="1"
              defaultValue="0"
            />
            <br />
            <button onClick={this.rotateLeft}>Rotate Left</button>
            <button onClick={this.rotateRight}>Rotate Right</button>
            <br />
            <button onClick={this.onBtnClk}>Upload</button> 
            </div>
            
          </div>
        )
      }
      else if(this.state.triggerMapper === true){
        return(
          <Mapper image={this.state.image}/>     
        );
        
      } 
    }
}
const rootElement = document.getElementById("root");
ReactDOM.render(<Upload />, rootElement);