import {Amplify} from 'aws-amplify';
import config from './aws-exports';
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import "./styles.scss";


Amplify.configure(config);

const formFields = {
    signUp: {
      email: {
        order:1
      },
      username: {
        order: 2
      },
      password: {
        order:3
      },
      confirm_password: {
        order: 4
      },     
      profession: {
        order: 6,
        labelHidden: false,
        placeholder: 'Profession',
        isRequired: false,
      },
      birthdate: {
        order: 5,
        isRequired:false,
      },
      name: {
        order: 7,
        labelHidden: false,
        placeholder: 'Name',
        isRequired: false,
      },
      locale: {
        order: 8,
        labelHidden: false,
        placeholder: 'Location',
        isRequired: false,
      },
      gender: {
        order: 9,
        labelHidden: false,
        placeholder: 'Male/Female',
        isRequired: false,
      },


   },
}

const Login = () => {
    return(
        <div className="auth">
        <Authenticator formFields={formFields} signUpAttributes={[ 'email', 'birthdate']}>
            {({ signOut, user }) => (
                <main>
                <h1>Hello {user.username}</h1>
                <button onClick={signOut}>Sign out</button>
                </main>
      )}

    </Authenticator>
    </div>
    )
}
            
export default Login;