import React from 'react';
import Post from './Post';
import './style.css';



const Posts = ({posts}) => {
  return (
    <section>
      <div className="title">
        <h2 style={{textAlign: "center"}}>Sign up and share your desk setup!</h2>
      </div>
      <div id="PostSection">
      {posts.map( (Model) => {
        const varr = JSON.parse(Model.listtags);
        return (
          <div>
            <Post id={Model.id} userID={Model.userid} imgURL={Model.imgURL} timestamp={Model.createdAt} listTags={varr}/>
          </div>
        )
    
     })}
      </div>
      
    </section>
  );
};

export default Posts;