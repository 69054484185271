import React from 'react';
import styled from 'styled-components';
import Burger from './Burger';

const Nav = styled.nav`
  width: 100%;
  height: 55px;
  border-bottom: 2px solid #f1f1f1;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  background-color: black;
  position: sticky;
  overflow: visible;
  color:white;
  top: 0;
  text-decoration: none;
  z-index: 200;
  flex-direction: row;
  align-items: center;
  padding-left: 2rem;
`

const Navbar = () => {
  return (
    <Nav>
      <div className='logo'>
        <span className='numbers'>1</span><span className='highlightn'>0</span><span className='numbers'>1 </span><span className='letters'>S</span><span className='highlightl'>e</span><span className='letters'>tups</span>
      </div>
      <Burger />
    </Nav>
  )
}

export default Navbar
