import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ReactGA from "react-ga4";
import reportWebVitals from './reportWebVitals';

const TRACKING_ID = "G-ZFJLLTR46P";

ReactGA.initialize(TRACKING_ID);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root') 
);
reportWebVitals(SendAnalytics);

