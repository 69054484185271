/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "desksapi",
            "endpoint": "https://yse5lp5ni0.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://65hbfkga2begjllk6dzcgwaepa.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2mpr7edw3rdcpjhze4xlli3lva",
    "aws_cognito_identity_pool_id": "eu-west-2:6761508a-79c6-4b7b-a500-8c9370b8e37d",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_NoYZiVE0w",
    "aws_user_pools_web_client_id": "36ko9hp78nbo0e4tcv3bejvhgs",
    "oauth": {
        "domain": "desksetupp7a54a7dd-7a54a7dd-master.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.101setups.com/,https://www.101setups.com/,https://www.101setups.com/",
        "redirectSignOut": "https://www.101setups.com/,https://www.101setups.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-west-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "posts-master",
            "region": "eu-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "postsimgs134240-master",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
