import React from 'react';
import ReactCrop from 'react-image-crop';
import { useState } from 'react';
//import { render } from '@testing-library/react';
import 'react-image-crop/dist/ReactCrop.css';
import './styles.scss';
import {Amplify, Auth, Storage,DataStore, API} from 'aws-amplify';
import config from './aws-exports';
import {Post} from "./models";
import { useNavigate } from 'react-router';


Amplify.configure(config);


const Mapper = ({image}) => {
    const [editTagMode, setEditTagMode] = useState(false);
    const [newTagMode, setNewTagMode] = useState(false);
    let [editingId, setEditingId] = useState(0);
    let [listTags, setListTags] = useState([]);
    let [tag,setTag] = useState({name: "", width: 0, height: 0, x: 0, y: 0});
    let [id, setId] = useState(0);
    const [name,setName] = useState("");
    const [category,setCategory] = useState("");
    const [productURL, setProductURL] = useState("");
    let [crop,setCrop] = useState({
        unit: '%',
    });
    const [username, setUsername] = useState("");
    const imgURL = URL.createObjectURL(image);
    let updateListTags = [];
    const errorName = "Please introduce the Make and Model";
    const errorCategory = "Please select a category from the dropdown";
    const errorSelection = "Please select an item on the picture";
    let [nameFlag, setNameFlag] = useState(false);
    let [categoryFlag, setCategoryFlag] = useState(false);
    let [selectionFlag, setSelectionFlag] = useState(false);
    const [timestamp,setTimestamp] = useState("");
    const navigate = useNavigate();


    const UK_urlperm = "https://www.amazon.co.uk/gp/search?ie=UTF8&tag=101setups-21&keywords=";
    const ES_urlperm = "https://www.amazon.es/s?k="
    const US_urlperm = "https://www.amazon.com/s?k="

    const changeNewTagMode = () =>{
        setNewTagMode(!newTagMode);
        setNameFlag(false);
        setCategoryFlag(false);
        setSelectionFlag(false);
        setCrop({unit: '%'});
    }

    const changeEditTagMode = () =>{
        setEditTagMode(!editTagMode);
        setNameFlag(false);
        setCategoryFlag(false);
        setCrop({unit: '%'});
        setSelectionFlag(false);
    }

    const addNewTag = () => {
        if (category === "" && name === "" && (crop.width + crop.height) === 0){
            setNameFlag(true);
            setCategoryFlag(true);
            setSelectionFlag(true);
        }
        else if (name !== "" && category !== "" && (crop.width + crop.height) !== 0){
                tag = {name: name, url: name, category: category, unit: crop.unit, width: crop.width, height: crop.height, x: crop.x, y: crop.y};
                setTag(tag); //Hemos quitado de la línea de arriba url: urlperm+name
                setId(id+1);
                setListTags(listTags.concat({tag,id}));
                setTag([]);
                setCrop({unit: '%'});
                setName("");
                setCategory("");
                setProductURL("");
                setNewTagMode(!newTagMode);
                setNameFlag(false);
                setCategoryFlag(false);
        }
        else if(category === "" && name !== "" && (crop.width + crop.height) !== 0){
            setCategoryFlag(true);
            setNameFlag(false);
            setSelectionFlag(false);
        }
        else if(category !== "" && name !== "" && (crop.width + crop.height) === 0){
            setCategoryFlag(false);
            setNameFlag(false);
            setSelectionFlag(true);
        }
        else if(category !== "" && name === ""  && (crop.width + crop.height) !== 0){
            setNameFlag(true);
            setCategoryFlag(false);
            setSelectionFlag(false);
        }
        else if(category === "" && name === ""  && (crop.width + crop.height) !== 0){
            setSelectionFlag(false);
            setNameFlag(true);
            setCategoryFlag(true);
        }
        else if(category === "" && name !== ""  && (crop.width + crop.height) === 0){
            setSelectionFlag(true);
            setNameFlag(false);
            setCategoryFlag(true);
        }
        else if(category !== "" && name === ""  && (crop.width + crop.height) === 0){
            setSelectionFlag(true);
            setNameFlag(true);
            setCategoryFlag(false);
        }
    }

    const editTag = (ref,target) => {
        listTags.forEach((tag,id) => {
            if(id===ref){
                setCrop({unit: target.tag.unit, width: target.tag.width, height: target.tag.height, x: target.tag.x, y: target.tag.y});
                setName(target.tag.name);
                setProductURL(target.tag.name); //Quitamos urlperm +
                console.log(productURL);
                setCategory(target.tag.category);
                setEditTagMode(true);
                setEditingId(ref);
            }
        })
    }

    const updateTag = () => {
        if (category === "" && name === ""){
            setNameFlag(true);
            setCategoryFlag(true);
        }
        else if (name !== "" && category !== ""){
            const tag1 = {name: name, category: category, unit: crop.unit, width: crop.width, height: crop.height, x: crop.x, y: crop.y};
            setTag(tag1);
            listTags.forEach((tag,id) => {
                if(id===editingId){ 
                    tag = tag1;
                    updateListTags = [...listTags];
                    updateListTags[editingId] = {tag,id};
                    setListTags(updateListTags);       
                    setEditingId(0);
                    setCrop({unit: '%'});
                    setName("");
                    setCategory("");
                    setEditTagMode(false);
                    updateListTags = [];
                    setNameFlag(false);
                    setCategoryFlag(false);
                }
            })
        }
        else if(category === "" && name !== ""){
            setCategoryFlag(true);
            setNameFlag(false);
        }
        else if(category !== "" && name === ""){
            setNameFlag(true);
            setCategoryFlag(false);
        }
    }

    const deleteTag = (ref,target) => {
        listTags.forEach((tag,id) => {
            if(id===ref){
                updateListTags = [...listTags];
                updateListTags.splice(id,1);
                setListTags(updateListTags);
                updateListTags = [];
            }
        })
    }

    const uploadImage = async() => {
        try{
            const userr = await Auth.currentAuthenticatedUser();
            const usernamee = (userr.username);
            setUsername(usernamee);

        }
        catch{
            console.log("error");
        }
            const date = Date.now();
            setTimestamp(new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(date));
            const filename = username+image.name;

        try{
            const key = await Storage.put(filename,image);
            const url = await Storage.get(key);
            return key;
        }
        catch{
            console.log("error");
        }
    }

    const post = async() => {
        //setLoading(true);

        console.log()
        try{
            const key = await uploadImage();
            //API.get('desksapi', '/items/Userid').then((deskRes) => console.log(deskRes));
            const userr = await Auth.currentAuthenticatedUser();

            await DataStore.save(
                new Post ({
                    id: '1234',
                    userid: JSON.stringify(userr.username),
                    imgURL: JSON.stringify(key),
                    timestamp: timestamp,
                    listtags: JSON.stringify(listTags)
                }));
                navigate('/');

            //const postt = await DataStore.query(Post).then(result => console.log(result));
        }
        
        catch(error){
            console.log("Error creating entry on Datastore: "+error);
        }
    }

    const handleChange = (e) =>{
        setName(e.target.value);
    }

    const handleChangeDrop = (e) =>{
        setCategory(e.target.value);
    }

    const onCropComplete = (crop, percentCrop) => {
        setCrop(percentCrop);
    };
    const onCropChange = (crop, percentCrop) => {
        setCrop(percentCrop);
    };

    /* Standard screen showing tags */
    if (!newTagMode && !editTagMode && listTags !== []){
        return (

                <div className="map-box" >
                    <div className="map-box-image">
                        <div className="ReactCrop ReactCrop--active ReactCrop--new-crop ReactCrop--rule-of-thirds" tabIndex="0">
                            <div>
                                <img className="ReactCrop__image" src={imgURL} alt="Didn't workkkkk" width = '100%' />
                                    {listTags.map((tag,id) => {
                                        return(
                                            <div>
                                                <div className="crop-item" key={id} onClick={() => editTag(id, tag)} style={{ unit: tag.tag.unit, width: tag.tag.width + '%', height: tag.tag.height + '%', left: tag.tag.x + '%', top: tag.tag.y + '%' }}/>
                                                <div className="crop-dot"  onClick={() => deleteTag(id,tag)} style={{ left: (tag.tag.x+tag.tag.width) + '%', top: tag.tag.y + '%' }} />
                                            </div>
                                        );
                                    })}
                            </div>
                        </div>
                    </div>
                
                <div className="listItem">
                    <div className="header-list">
                        <h2>Tag your items:</h2>
                    </div>
                    <div className="list">
                        <ul className="properList">
                            {listTags.map((tag,id) => {
                                return(
                                    <li className="listofItems" key={id} >
                                        <span onClick={() => editTag(id,tag)}>{tag.tag.name}</span>
                                    <div className="crop-dot-list" onClick={() => deleteTag(id,tag)}/></li>
                                );
                            })}
                            <li className="newItem" onClick={changeNewTagMode}>Add new item</li>
                        </ul>
                    </div>
                    <div className="button-box">
                        <br></br><button id="add" onClick={changeNewTagMode}>Add tag</button>
                        <button id="complete" onClick={post}>Done</button>
                    </div>
                </div>
            </div>
        )
    }

    /* Initial screen NO tags */
    else if(!newTagMode && listTags === []){
        return (
            <div className="map-box" >
                <div className="map-box-image">
                    <div className="ReactCrop ReactCrop--active ReactCrop--new-crop ReactCrop--rule-of-thirds" tabIndex="0">
                        <div>
                            <img className="ReactCrop__image" src={imgURL} alt="Didn't workkkkk" width = '100%'/>
                        </div>
                    </div>
                </div>
            
                <div className="listItem">
                    <div className="header-list">
                        <h2>Tag your items:</h2>
                    </div>
                    <ul>
                        <li className="newItem" onClick={changeNewTagMode}>Add new item</li>
                    </ul>
                    <div className="button-box">
                        <button id="add" onClick={changeNewTagMode}>Add tag</button>
                        <button id="complete">Done</button>
                    </div>
                </div>
            </div>
        )
    }

    /* New tag Screen */
    else if(newTagMode){
        return (
            <div className="map-box">   
                <div className="map-box-image">
                    <ReactCrop src={imgURL} crop={crop} onChange={onCropChange} onComplete={onCropComplete}/>
                </div>
                <div className="listItem">
                    <div className="header-list">
                        <h2>Add your item:</h2>
                    </div>
                    <div className="edit-box">
                        <div className="field-box">
                            <h3> Name* </h3>
                            <input
                                className="input-box"
                                name="Name"
                                type="text"
                                onChange={handleChange}
                                required
                            />
                           <h4 className="error"> {nameFlag ? (errorName) : ''} </h4>
                        </div>
                        <div className="field-box">
                            <h3> Category* </h3>
                        
                            <select className="dropdown" required onChange={handleChangeDrop}>
                                <option value="" selected="selected" label =""></option>
                                <option value="Keyboard" label ="Keyboard"></option>
                                <option value="Monitor" label ="Monitor"></option>
                                <option value="Desk" label ="Desk"></option>
                                <option value="Chair" label ="Chair"></option>
                                <option value="Camera" label ="Camera"></option>
                                <option value="Monitorstand" label ="Monitor stand"></option>
                                <option value="Cablemanagement" label ="Cable management"></option>
                                <option value="Notebook" label ="Notebook"></option>
                                <option value="Streaming" label ="Streaming"></option>
                                <option value="Light" label ="Light"></option>
                                <option value="Lamp" label ="Lamp"></option>
                            </select>
                            
                            <h4 className="error"> {categoryFlag ? (errorCategory) : ''} </h4>
                        </div>
                        <h4 className="error"> {selectionFlag ? (errorSelection) : ''} </h4>
                    </div>
                    <div className="button-box">
                            <button id = "complete" name="submitTag" onClick={changeNewTagMode}> Cancel </button>
                            <button id = "add" name="submitTag" onClick={addNewTag}> Add tag </button>
                    </div>
                </div>
            </div>
        )
    }
    /* Edit Screen */ 
    else if(editTagMode){
        return(
            <div className="map-box">
                <div className="map-box-image">
                    <ReactCrop src={imgURL} crop={crop} onChange={onCropChange} onComplete={onCropComplete}/>
                </div>
                <div className="listItem">
                    <div className="header-list">
                        <h2>Edit your items:</h2>
                    </div>
                    <div className="edit-box">
                        <div className="field-box">
                            <h3> Name* </h3>
                            <input
                                className="input-box"
                                name="Name"
                                type="text"
                                value={name}
                                onChange={handleChange}
                            />
                            <h4 className="error"> {nameFlag ? (errorName) : ''} </h4>
                        </div>
                        <div className="field-box">
                            <h3> Category* </h3>
                        
                            <select className="dropdown" value={category} onChange={handleChangeDrop}>
                                <option value="" label =""></option>
                                <option value="Keyboard" label ="Keyboard"></option>
                                <option value="Monitor" label ="Monitor"></option>
                                <option value="Desk" label ="Desk"></option>
                                <option value="Chair" label ="Chair"></option>
                                <option value="Camera" label ="Camera"></option>
                                <option value="Monitorstand" label ="Monitor stand"></option>
                                <option value="Cablemanagement" label ="Cable management"></option>
                                <option value="Notebook" label ="Notebook"></option>
                                <option value="Streaming" label ="Streaming"></option>
                                <option value="Light" label ="Light"></option>
                                <option value="Lamp" label ="Lamp"></option>
                                <option value="Other" label ="Other"></option>
                            </select>
                            <h4 className="error"> {categoryFlag ? (errorCategory) : ''} </h4>
                        </div>
                        <h4 className="error"> {selectionFlag ? (errorSelection) : ''} </h4>
                    </div>
                    <div className="button-box">
                        <button name="submitTag" id="complete" onClick={changeEditTagMode}> Cancel </button>
                        <button name="submitTag" id="add" onClick={updateTag}> Update </button>
                    </div>
                </div>
            </div>
        )
    }
}
export default Mapper;