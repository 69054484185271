import styled from 'styled-components';
import React, {useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import './style.css';
import {Amplify,Auth} from 'aws-amplify';
import config from './aws-exports';


Amplify.configure(config);

const Ul = styled.ul`
  list-style: none;
  flex-flow: row nowrap;
  display: flex;
  li {
    padding: 18px 10px;
  }
  @media (max-width: 485px) {
    flex-flow: column nowrap;
    background-color: #0D2538;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    li {
      color: #fff;
    }
  }
`;

const RightNav = ( {open, setOpen} ) => {
    
    const [logged,setLogged] = useState(false);
    const [userName,setUserName] = useState("");

const signOut = async () => {
    try{
        await Auth.signOut();
        setLogged(false);

    } 
    catch{
        console.log("error");
        
    }
}

  return (
    <div>
        <Ul className='nav-links' open={open}>
                <li className='nav-link' ><NavLink to="/" className='nav-link' onClick={() => setOpen(!open)} >Home</NavLink></li>
                <li className='nav-link' ><NavLink to="/ImgDropAndCrop" className='nav-link'onClick={() => setOpen(!open)}>Upload</NavLink></li>
                {logged ? (<li className='nav-link-user'>{userName} <button onClick={signOut}>Log out</button></li>) : (<li className='nav-link' ><NavLink onClick={() => {signOut(); setOpen(!open)}} to="/Login" className='nav-link'>Login</NavLink></li>)}
        </Ul>
    </div>
  )
}


export default RightNav