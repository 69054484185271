import React, {useState, useEffect} from 'react';
import './styles.scss';
import 'react-image-crop/dist/ReactCrop.css';
import {Amplify,Storage} from 'aws-amplify';
import config from './aws-exports';
import { Alert } from '@aws-amplify/ui-react';
import ReactTooltip from 'react-tooltip';
import useAnalyticsEventTracker from './useAnalyticsEventTracker';

const UK_urlperm = 'https://www.amazon.co.uk/gp/search?ie=UTF8&tag=101setups-21&keywords=';
const ES_urlperm = 'https://www.amazon.es/s?k=';
const US_urlperm = 'https://www.amazon.com/s?k=';


Amplify.configure(config);
//const external = (url) => {
 // const gaEventTracker = useAnalyticsEventTracker('Amazon opened');
 // window.open(url);
//}
const External = ({ url }) => {
  const gaEventTracker = useAnalyticsEventTracker('Amazon opened');
  window.open(url);
  return null; // or return some JSX if needed
}
const handleContent = dataTip => {
  ReactTooltip.rebuild();
  if (!dataTip) {
    return "";
  }
  const [name, url] = dataTip.split("|");
  //const gaEventTracker = useAnalyticsEventTracker('Item tag');
  return name ? (
    <div>
        <div>
          <h3>Product: {name}</h3>
        </div>
        <div>
          <button class="buyButton" onClick={() => External({ url })}>Buy</button>
        </div>
    </div> 
  ) : null;
};

const Post =  ({ id, userID, imgURL, timestamp, listTags }) => {
  //const [key, setKey] = useState();
   
  const [url, setUrl] = useState("http://www.amazon.co.uk");
  const [showAlert,setShowAlert] = useState(false);
  const [location, setLocation] = useState(null);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        setLocation(data.country_code);
      } catch (error) {
        console.error('Error fetching location:', error);
      }
    };
    fetchLocation();
  }, []);

  const getAmazonLink = () => {
    switch (location) {
      case 'ES':
        return ES_urlperm;
      case 'US':
        return US_urlperm;
      case 'UK':
        return UK_urlperm;
      default:
        return UK_urlperm; // Default to Amazon.com
    }
  };

  const copyURL = (id) => {
    const url = "www.101setups.com/shared/"+id;
    navigator.clipboard.writeText(url);
    setShowAlert(true);
  }
  useEffect( async () => {
    async function getURL({key}) {
        try{
          const urlll = await Storage.get(key,{
            level: 'public'
          });
          return urlll;
        }
        catch (error){
        console.log(error);
        }
    }
    const keyy = JSON.parse(imgURL);
    const urll = await getURL(keyy);
    setUrl(urll);
  }, []);

    return (
      <article className='single-post'>
              <div className="header">
              <span className="userpost">{userID.slice(1,-1)}</span>
              
            </div>
        <div className="image" tabIndex="0">
          <div className="ReactCropp">
            <img src={url} alt="Error loading"/>
            {listTags.map((tag,id) => {
              const urllll = getAmazonLink()+tag.tag.name;
                return(
                    <div className="crop-item" key={id} data-for='label' data-tip={`${tag.tag.name}|${urllll}`} data-event={'click focus'}   clickable={true}  html={true} style={{ unit: tag.tag.unit, width: tag.tag.width + '%', height: tag.tag.height + '%', left: tag.tag.x + '%', top: tag.tag.y + '%' }}></div>
                );
            })}
          </div>
          <ReactTooltip className="rtt" place="bottom" effect="solid" id='label' multiline={true}
              getContent={handleContent} />
        </div>
        
        <footer>
          <div className="list">
          <div className="interaction">
              <button className="share" onClick={() => copyURL(id)}>Share post</button>
              {showAlert ? <Alert
                variation="success"
                isDismissible={true}
                hasIcon={false}
                heading="Link copied to Clipboard!"
                >
                Now you can Paste the link and share this post with anyone!
              </Alert> : null}
            </div>
            <ul className="properList">
                {listTags.map((tag,id) => {
                  return(
                    <li className="listofItems" key={id}>
                        <span>{tag.tag.name}</span>
                    </li>
                  )
                })}
            </ul>
          </div>
        </footer>
      </article>
    );
};
export default Post;