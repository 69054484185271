import React from 'react';
import {ReactComponent as Instagram} from './icons/icons8-instagram.svg';
import {ReactComponent as Twitter} from './icons/icons8-twitter.svg';
import {ReactComponent as Pinterest} from './icons/icons8-pinterest.svg';
import {ReactComponent as Facebook} from './icons/icons8-facebook.svg';
import ConvertKitForm from 'convertkit-react';
import './style.css';

const MY_FORM_ID = 5547918;

const external = (url) => {
    window.open(url);
}

const config = {
    formId: MY_FORM_ID,
    template: 'clare',
    headingText: '🌍 Subscribe to Weekly Setups',
    format: 'inline',
    namePlaceholder: 'Enter your name',
    emailPlaceholder: 'Enter your email address',
    disclaimerText: 'No spam, no ads, no sales. Unsubscribe at any time.',
    submitText: 'Subscribe',
  }


class Footer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        }
    }

    render() {
        return(
            <div className="foot">
                <div className="newsletter">
                    <div className="form">
                    <h3>🌍 Subscribe to Weekly Setups</h3>
                    <p>We are building a community of desk setup lovers. Once a week we share the coolest gadgets and desk setups to inspire you with fresh ideas.</p>
                    </div>
                    <div className="form">
                        <ConvertKitForm {...config} >
                            <p>We are building a community of desk setup lovers. Once a week we share the coolest gadgets and desk setups to inspire you with fresh ideas.</p>
                        </ConvertKitForm>
                    </div>
                </div>
                <div className="footer">
                    <div className="copyright">© 101 Setups 2023</div>
                    <div className="social">
                        <nav className="followus">
                            <strong>Follow Us</strong>
                        </nav>
                        <div className="social-links">
                            <Instagram className="icon" onClick={() => external("http://www.instagram.com/101.setups")}/>
                            <Twitter className="icon" onClick={() => external("http://www.instagram.com/101.setups")}/>
                            <Facebook className="icon" onClick={() => external("http://www.instagram.com/101.setups")}/>
                            <Pinterest className="icon" onClick={() => external("http://www.instagram.com/101.setups")}/>
                        </div>
                    </div>
                </div>
            </div>


        )
    }
}
export default Footer;
